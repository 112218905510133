import createAuthContext from './lib/createAuthContext'

const clientId = "1e6a279c-8898-43ba-b549-7e8027a2503d"
const clientSecret = "";//"1eeb36df-dfff-47b8-b0fb-437870fb9c3b"

//New Policy New Journey
//B2C_1_SignUpAndSignInFlow 

//Old Policy - new journey
//B2C_1_SignUpAndSignInFlow 

const provider = "https://happyreadingclub.b2clogin.com/happyreadingclub.onmicrosoft.com/B2C_1_SignUpAndSignInFlow/oauth2/v2.0"
const tokenEndpoint = "https://happyreadingclub.b2clogin.com/happyreadingclub.onmicrosoft.com/B2C_1_SignUpAndSignInFlow/oauth2/v2.0/token"






export const {AuthContext, Authenticated, useToken} = createAuthContext({
  clientId,
  clientSecret,
  provider
})
