import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AuthContext, Authenticated } from './auth'
import Protected from './Protected'

import RegisterPage from './RegisterPage'



const basename = window.location.href ? '/react-pkce-sample' : undefined
//const basename = window.location.href 


function App() {
  return (
    <div style={{ padding: 20, background:'gray' }}>
      <h1>Hapy Reading Club Login Start from here</h1>
      <AuthContext>
        <div>
          <a id="home" className="menu-item" href="/">Home</a> &nbsp;&nbsp;
          <a id="home" className="menu-item" href="/protected">Login</a>
        </div>
        <BrowserRouter>
          <Routes>
          <Route path="/" element={<div >This is ADB2C Demo</div>} />
            <Route path="/protected" element={<Authenticated>
              <Protected />
            </Authenticated>} />
          </Routes>
        </BrowserRouter>
      </AuthContext>
    </div>
  );
}

export default App;
